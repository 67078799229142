<template>
  <v-navigation-drawer v-model="drawer" fixed hide-overlay app>
    <v-list nav dense>
      <v-list-item-group v-model="group">
        <v-list-item link to="/">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/treinos">
          <v-list-item-icon>
            <v-icon>mdi-weight-lifter</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Treinos</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/login">
          <v-list-item-icon>
            <v-icon>mdi-exit-run</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Sidebar",
  data: () => ({ group: null }),
  computed: {
    drawer: {
      get() {
        return this.$store.state.sidebar.show;
      },
      set(v) {
        this.$store.commit("sidebar/setShow", v);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>