<template>
<v-checkbox
              v-model="saveDevice"
              label="Salvar este dispositivo"
              hide-details
            ></v-checkbox>
</template>

<script>
  export default {

    computed:{
      saveDevice:{
        get(){
          return this.$store.state.login.saveDevice
        },
        set(v){
          this.$store.commit('login/setSaveDevice', v)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>