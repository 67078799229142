<template>
  <v-app id="app" dark>
    <v-main>
      <v-container fluid>
        <router-view />
    <Loading />
    <Alerts />
    <Gif />
      </v-container>
      
    </v-main>
  </v-app>
</template>
<script>
import Alerts from './components/Modals/Alerts.vue';
import Loading from './components/Modals/Loading.vue'
import Gif from './components/Modals/Gif.vue'
export default {
  components: {
    Loading,
    Alerts,
    Gif
  },
};
</script>

<style lang="scss">
</style>
