export default {
  namespaced: true,
  state: ()=>({
    showLoading: false,
    showAlerts: false,
    alertMessage: "",
    showGif: false,
    urlGif: ''
  }),
  mutations:{
    setUrlGif(state, url){
      state.urlGif = url
    },
    setShowGif(state, value){
      state.showGif = value
    },
    setShowLoading(state, value){
      state.showLoading = value
    },
    setShowAlerts(state, value){
      state.showAlerts = value
    },
    setAlertMessage(state, value){
      state.alertMessage = value
    }
  },
  actions:{
    loginFailed({commit}, message){
      commit('setAlertMessage', message)
      commit('setShowAlerts', true)
    }
  }
}