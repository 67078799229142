<template>
  <v-card>
    
    <v-expansion-panels v-model="expansed">
      <v-expansion-panel
        v-for="(item, i) of Object.keys(workouts.treinos)"
        :key="i"
      >
        <v-expansion-panel-header
          class="align-center ma-1"
          color="pink darken-4"
        >
          <span class="text-h6">Treino {{ item }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <List :data="workouts.treinos[item]" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
<script>
import List from "../Treinos/List.vue";
import axios from 'axios'
export default {
  props: ["userId"],
  components: { List },
  data: () => ({
    expansed: 0,
    workouts: { treinos: [] },
  }),
  computed: {
    name() {
      const split = this.workouts.name?.split(" ") || "";
      const firstName = split.slice(0, 1);
      const lastName = split.slice(-1);
      return firstName === lastName ? lastName : firstName + " " + lastName;
    },
  },
  methods: {
    async mounted() {
      // this.$store.commit('treinos/setFace', this.$store.state.baseUrl + '/student/avatar/'+this.userId+'.jpg')
      // this.$store.commit('modals/settimeout', this.$store.state.modals.defaultTimeout)
      // this.workouts = await this.$store.state.axios.get('/student/workouts/'+this.userId).then(data => data.data)
    },
  },
  async mounted(){
   this.workouts = await axios.get('/student/workouts/'+this.$store.state.login.user._id).then(data => data.data)
  }
};
</script>

<style lang="scss" scoped>
</style>