export default {
  namespaced: true,
  state: ()=>({
    phone: '',
    saveDevice: true,
    authenticated: false,
    user: {}
  }),
  mutations:{
    setPhone(state, value){
      state.phone = value
    },
    setSaveDevice(state, value){
      state.saveDevice = value
    },
    setAuthenticated(state, value){
      state.authenticated = value
    },
    setUser(state, user){
      state.user = user
    }
  }
}