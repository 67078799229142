<template>
  <v-dialog v-model="dialog" max-width="400">
    <v-card>
      <v-card-title>Atenção!</v-card-title>
      <v-card-text>
        <v-alert dense outlined type="error">
          {{ $store.state.modals.alertMessage }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="green darken-1" text @click="dialog = false"> ok </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    dialog: {
      get() {
        return this.$store.state.modals.showAlerts;
      },
      set(v) {
        this.$store.commit("modals/setShowAlerts", v);
      },
    },
  },
};
</script>