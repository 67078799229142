<template>
    <v-app-bar fixed app>
      <v-app-bar-nav-icon @click.stop="toggleSidebar"></v-app-bar-nav-icon>

      

      <v-spacer></v-spacer>


      <v-spacer></v-spacer>

    </v-app-bar>
</template>
<script>
export default {
  name: "Topbar",
  methods: {
    toggleSidebar(){
      this.$store.commit('sidebar/setShow', !this.$store.state.sidebar.show)
    },
  },
};
</script>