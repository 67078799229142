import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'
import Home from '../views/Home.vue'
import Login from '@/components/Login/Login';
import Treinos from '@/components/Treinos/Details';
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/treinos',
        name: 'Treinos',
        component: Treinos
      },
    ]
  },

  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  try {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')
    if (to.name !== 'Login' && !store.state.login.authenticated) {
      if (!token) return router.push('/login')
      const {data} = await axios.post('/login/aluno/verify', { token })
      store.commit('login/setAuthenticated', true)
      store.commit('setToken', token)
      store.commit('login/setUser', data.user)
      next()
    }
    else next()

  } catch (error) {
    router.push('/login')
  }
})

export default router
