export default {
  namespaced: true,
  state: ()=>({
    show: true
  }),
  mutations:{
    setShow(state, value){
      state.show = value
    }
  }
}