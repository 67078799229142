<template>
  <v-row justify="center">
    
    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        
        <v-img width="100%" :src="$store.state.modals.urlGif"></v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          x-large
            color="green darken-1"
            
            @click="dialog = false"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  computed:{
    dialog:{
      get(){
        return this.$store.state.modals.showGif
      },
      set(value){
        this.$store.commit('modals/setShowGif', value)
      }
    }
  }
}
</script>
