<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md3>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Entrar</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form class="frm">
                  <!-- <Alert1 :text="message1" :color="alertColor" /> -->

                  <v-text-field
                    v-model="login"
                    :prepend-icon="icon"
              
                    type="text"
                    outlined
                    autocomplete="new-password"
                    style="
                      visibility: hidden;
                      height: 0;
                      width: 1px;
                      position: absolute;
                      left: 0;
                      top: 0;
                    "
                  ></v-text-field>

                  <v-stepper v-model="e1" style="background: transparent!important" >
                    <!-- <v-stepper-header>
                      <template v-for="(item, index) in stepps">
                        <v-stepper-step
                          :key="index"
                          :complete="e1 > index + 1"
                          :step="index + 1"
                          edit-icon="mdi-check"
                          editable
                        >
                          {{ item }}
                        </v-stepper-step>
                        <v-divider
                          :key="item"
                          v-if="index + 1 < stepps.length"
                        ></v-divider>
                      </template>
                    </v-stepper-header> -->

                    <v-stepper-items>
                      <template v-for="(item, index) in stepps">
                        
                        <StepPhone
                          :key="index"
                          :step="index + 1"
                          :item="item"
                          @next="e1 = index + 2"
                          @reSendCode="resend => reSendCode = resend"
                          v-if="item == 'Telefone'"
                        />
                        <StepCode
                          :key="index"
                          :step="index + 1"
                          :e1="e1"
                          :item="item"
                          :reSendCode="reSendCode"
                          v-if="item == 'Código'"
                        />
                      </template>
                    </v-stepper-items>
                  </v-stepper>

                  <!-- <v-text-field
                    v-model="login"
                    hint="ex: 11988887777"
                    counter="11"
                    :prepend-icon="icon"
                    label="Telefone"
                    type="text"
                    outlined
                  ></v-text-field> -->

                  <!-- <v-text-field
                    v-model="password"
                    prepend-icon="mdi-rename"
                    label="Nome Completo"
                    hint="Informe seu nome completo"
                    type="text"
                    outlined
                    hide-details
                  ></v-text-field> -->
                  <Check1 />
                </v-form>
              </v-card-text>
              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" to="/code">Entrar</v-btn>
              </v-card-actions> -->
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
// import Alert1 from "../Alerts/Alert1.vue";
import Check1 from "./Check1.vue";
import StepPhone from './StepPhone.vue'
import StepCode from './StepCode.vue'

export default {
  name: "Login",
  components: {  Check1, StepPhone, StepCode },
  data: () => ({
    reSendCode: ()=>{},
    e1: 1,
    stepps: ["Telefone", "Código"],
    login: "",
    message1:
      "Olá, faça o login com seu número de telefone para ter acesso à plataforma!.",
    alertColor: "yellow",
    icon: "mdi-phone",
    showButtonFirstAccess: true,
  }),
  methods: {

  }
};
</script>

<style  scoped>
/* .frm >>> input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px black inset !important;
} */
</style>

