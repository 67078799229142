export default {
  namespaced: true,
  state: ()=>({
    showTreinos: false,
    face: ''
  }),
  mutations:{
    setShowTreinos(state, value){
      state.showTreinos = value
    },
    setFace(state, value){
      state.face = value
    }
  }
}