<template>
  <v-data-table
    :headers="headers"
    :items="data"
    class="elevation-1"
    disable-pagination
    hide-default-footer
    dense
    mobile-breakpoint="400"
  >
  <template #item.gif="{item}">
    <v-img :src="item.gif" width="100" @click="showGif(item.gif)" />
  </template>
  <template #item.name="{item}"><span class="text-h6">{{item.name}}</span></template>
  <template #item.series="{item}"><span class="text-h5">{{item.series}}</span></template>
  </v-data-table>
</template>
<script>

  export default {
    props: ['data'],
    data () {
      return {
        headers: [
          { text: 'Modelo', value: 'gif', sortable: false, },
          {
            text: 'Treino',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: 'Séries', value: 'series', sortable: false, },
        ]
      }
    },
    methods:{
      showGif(gif){
        this.$store.commit('modals/setUrlGif', gif)
        this.$store.commit('modals/setShowGif', true)
      }
    }
  }
</script>