<template>
  <v-stepper-content :step="step" class="pa-0">
    <v-card class="mb-5">
      <v-card-title>
        <v-icon>mdi-cellphone-message</v-icon>&nbsp;
        <div>Código de verificação SMS</div>
      </v-card-title>
      <v-otp-input
        v-model="otp"
        :disabled="loading"
        type="number"
        @change="verifyCode"
      ></v-otp-input>
    </v-card>

    <v-card-actions>
      <v-btn :disabled="sec > 0" @click="sendCode">{{
        sec ? "Tentar novamente em " + this.time : "Reenviar código"
      }}</v-btn>
    </v-card-actions>
  </v-stepper-content>
</template>

<script>
import axios from 'axios'
const timeout = (time = 1000) =>
  new Promise((resolve) => setTimeout(resolve, time));
function segundosParaTempo(segundos) {
  const horas = Math.floor(segundos / 3600);
  const minutos = Math.floor((segundos - horas * 3600) / 60);
  const segundosRestantes = segundos % 60;

  //const horasFormatadas = horas.toString().padStart(2, '0');
  const minutosFormatados = minutos.toString().padStart(2, "0");
  const segundosFormatados = segundosRestantes.toString().padStart(2, "0");

  return `${minutosFormatados}:${segundosFormatados}`;
}
export default {
  props: ["step", "item", "reSendCode", "e1"],
  data: () => ({
    otp: "",
    loading: false,
    time: "00:00",
    sec: 120,
  }),
  methods: {
    async verifyCode() {
      this.loading = true;
      
      await axios.post("/login/verify-code", {code: this.otp, phone: this.$store.state.login.phone})
        .then(({data}) => {
          this.$store.dispatch('setToken', data)
          this.$router.push('/')
          this.$store.commit('login/setAuthenticated', true)
        })
        .catch(err => this.$store.dispatch('modals/loginFailed', err.response?.data?.error || "Falha técnica: " + err.stack));
      this.loading = false; 
    },

    sendCode() {
      if (this.sec) return;
      this.reSendCode();
      this.sec = 120;
      this.timeOutSendCode();
    },
    async timeOutSendCode() {
      this.time = segundosParaTempo(this.sec);
      while (this.sec) {
        await timeout();
        this.sec = this.sec - 1;
        this.time = segundosParaTempo(this.sec);
      }
    },
  },
  watch: {
    phone(v) {
      this.$emit("input", v);
    },
    async e1(v) {
      if (v !== 2) return;
      this.timeOutSendCode();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>