<template>
  <v-stepper-content :step="step" class="pa-0">
    <v-card>
      <v-card-title>
        {{ item }}
      </v-card-title>
      <v-text-field
        v-model="phone"
        outlined
        dense
        label="Telefone"
        hint="ex: 11988887777"
        type="number"
      ></v-text-field>
    </v-card>

    <v-card-actions>
      <v-btn color="primary" :disabled="this.$store.state.login.phone.length < 11" @click="login">
        Continue
      </v-btn>
    </v-card-actions>
  </v-stepper-content>
</template>

<script>
import axios from 'axios'
export default {
  props: ["step", "item"],
  data: () => ({
    phone: "",
  }),
  methods:{
    async login(){
      this.$store.commit("modals/setShowLoading", true);
      await axios
        .post("/login/aluno", { phone: this.$store.state.login.phone })
        .then(() => {
          this.$emit('next')
          this.$emit('reSendCode', this.login)
        })
        .catch((e) => {
          const err = e.response?.data?.error || e.stack
          this.$store.dispatch('modals/loginFailed', err)
        });
      this.$store.commit("modals/setShowLoading", false);
    }
  },
  watch: {
    phone(v) {
      this.$store.commit('login/setPhone', v)
    },
  },
};
</script>

<style lang="scss" scoped>
</style>