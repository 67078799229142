import Vue from 'vue'
import Vuex from 'vuex'
import treinos from './treinos'
import sidebar from './sidebar'
import topbar from './topbar'
import modals from './modals'
import login from './login'
import axios from 'axios'
axios.defaults.baseURL = 'https://' + process.env.VUE_APP_SERVER_URL + ':'+ process.env.VUE_APP_SERVER_PORT;
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    token: null
  },
  mutations: {
    setToken(state, value){
      state.token = value
      axios.defaults.headers.common['token'] = value
    }
  },
  actions: {
    setToken({commit}, token){
      !token.token || commit('setToken', token.token)
      commit('login/setUser', token.user)
      this.state.login.saveDevice ? window.localStorage.setItem('token', token.token) : window.sessionStorage.setItem('token', token.token)
    }
  },
  modules: {
    treinos,
    topbar,
    sidebar,
    modals,
    login
  }
})
